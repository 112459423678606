import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import DefaultLayout from "/opt/build/repo/src/templates/blog-post.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <h1>{`How to build a portfolio`}</h1>
    <p>{`Deciding on allocations is the most difficult task in investing. There are endless possibilities and and clear right and wrong dowsn't exist.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      